import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { colors, db } from "../components/siteVars"
import Iframe from "../components/iframe"
import Button from "../components/button"
import Axios from "axios"
import { getUser } from "../components/handle-auth"
import { navigate } from "gatsby"

const StyledWelcomePage = styled.div`
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  flex-direction: column;
  .text-container {
    margin: 4rem 0 0 0;
    p {
      font-size: 2rem;
    }
  }
  .welcome-video-container {
    width: 100%;
  }
`

export default class WelcomePage extends Component {
  state = { user: {} }
  componentDidMount() {
    async function awaitUser(that) {
      const user = await getUser()
      console.log(user)
      that.setState({
        user: user,
      })
    }
    awaitUser(this)
  }

  goToApp = () => {
    Axios.post(db.lambda + "users/firstLogin/" + this.state.user._id)
      .then(res => {
        console.log(res.data)
        navigate("/planner")
      })
      .catch(err => {
        console.log(err)
        navigate("/planner")
      })
  }

  render() {
    const link = `
    <iframe
    width="1280"
    height="720"
    src="https://www.youtube.com/embed/wcBNXB69YL8"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    />
    `

    return (
      <Layout background>
        <StyledWelcomePage>
          <div className="text-container">
            <h1>Welcome to teamgym planner</h1>
            <Button color="green" onClick={this.goToApp}>
              Start using the app
            </Button>
            <p>
              Or watch this short video on how to get the most out of the app
            </p>
          </div>
          <div className="welcome-video-container">
            <Iframe>{link}</Iframe>
          </div>
        </StyledWelcomePage>
      </Layout>
    )
  }
}
