import React from "react"
import styled from "styled-components"

const StyledYoutube = styled.div`
  .iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 2rem;
  }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Iframe = props => {
  return (
    <StyledYoutube>
      <div
        className="iframe-container"
        dangerouslySetInnerHTML={{ __html: props.children }}
      ></div>
    </StyledYoutube>
  )
}

export default Iframe
